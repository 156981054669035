import React, { useEffect, useState } from 'react';
import TextInput from "../components/TextInput";
import Button from '../components/Button';
import $ from "jquery";
import PasswordStrengthBar from 'react-password-strength-bar';
import axios from 'axios';
import ImageUploader from '../components/ImageUploader';
import { validateEmail, inputErrorAnimation } from '../utils/validations/validations';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const scoreWords = ['Muito fraca', 'Fraca', 'Regular', 'Forte', 'Muito forte'];

const Login = (props) => {
    const [loadingCreateProfile, setLoadingCreateProfile] = useState(false);
    const [loading, setLoading] = useState(true);

    const [userUuid, setUserUuid] = useState('');
    const [registerName, setRegisterName] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerPhone, setRegisterPhone] = useState('');
    const [registerCpf, setRegisterCpf] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerConfirmPassword, setRegisterConfirmPassword] = useState('');
    const [userImage, setUserImage] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        getUser();
    }, [])
    async function getUser() {
        setLoading(true);
        const queryString = window.location.href.split('form/')[1];
        const fetchData = {
            method: "GET",
            url: `${process.env.REACT_APP_ACCESS_V1_URL}/users/${queryString}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-API-KEY': process.env.REACT_APP_DEV_TOKEN,
                'x-request-id': uuidv4()
            }
        }
        try {
            const response = await axios(fetchData);
            setLoading(false);
            if (response && response.data) {
                setRegisterName(response.data.name && response.data.name);
                setRegisterEmail(response.data.email && response.data.email);
                setRegisterPhone(response.data.telephone && response.data.telephone);
                setRegisterCpf(response.data.identifier && response.data.identifier);
                setUserUuid(response.data.uuid && response.data.uuid);
            }
            return response;
        } catch (error) {
            props.notify('Algo deu errado! Tente novamente ou entre em contato com o administrador.');
            setLoading(false);
            navigate('/');
            console.log(error)
            return error;
        }
    };

    async function createProfile() {
        if (registerName === "") {
            props.notify("Preencha o campo nome!");
            inputErrorAnimation('register-name');
        } else if (registerEmail === "") {
            props.notify("Preencha o campo e-mail!");
            inputErrorAnimation('register-email');
        } else if (!validateEmail(registerEmail)) {
            props.notify("O e-mail fornecido não é válido!");
            inputErrorAnimation('register-email');
        } else if (registerPhone === "") {
            props.notify("Preencha o campo telefone!");
            inputErrorAnimation('register-phone');
        } else if (registerCpf === "") {
            props.notify("Preencha o campo cpf!");
            inputErrorAnimation('register-cpf');
        } else if (registerPassword === "") {
            props.notify("Preencha o campo senha!");
            inputErrorAnimation('register-password');
        } else if (registerPassword.length < 8) {
            props.notify("A senha precisa conter ao menos 8 caracteres!");
            inputErrorAnimation('register-password');
        } else if (registerPassword !== registerConfirmPassword) {
            props.notify("Confirme sua senha novamente!");
            inputErrorAnimation('register-password');
            inputErrorAnimation('confirm-register-password');
        } else {
            setLoading(true);
            const fetchData = {
                method: "PUT",
                url: `${process.env.REACT_APP_ACCESS_V1_URL}/users/${userUuid ? userUuid : null}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': "application/json",
                    'X-API-KEY': process.env.REACT_APP_DEV_TOKEN,
                    'x-request-id': uuidv4()
                },
                data: {
                    "name": registerName,
                    "telephone": registerPhone.replaceAll(" ", "").replace("+55", "").replace("-", "").replace("(", "").replace(")", ""),
                    "status": "ACTIVE",
                    "password": registerPassword,
                    "form": "completeRegister"
                }
            }
            if (userImage) {
                fetchData.data.profilePicture = userImage
            }
            try {
                const response = await axios(fetchData);
                setLoading(false);
                setLoadingCreateProfile(true);
                props.successNotify('Cadastro concluído com sucesso!');
                navigate('/');
                return response;
            } catch (error) {
                setLoading(false);
                props.notify('Algo deu errado! Tente novamente.');
                console.log("error", error);
                return error;
            }
        }
    };
    return (
        <div className="register-container">
            <div className="register-form">
                <div className="register-logo"></div>
                <p className="register-title">Cadastro</p>
                {loadingCreateProfile ?
                    <div className="loading-container register">
                        <div className="loading-icon" id="register-loading"></div>
                        <div className="success-response" id="register-success">Perfil criado com sucesso!</div>
                    </div>
                    : loading ?
                        <div className="loading-container register"><div className="loading-icon"></div></div>
                        :
                        <>
                            <ImageUploader setUserImage={(base64) => setUserImage(base64)} />
                            <TextInput
                                name="register-name"
                                placeholder="Nome"
                                value={registerName}
                                onChange={(event) => setRegisterName(event.target.value)}
                                className="no-mr"
                            />
                            <TextInput
                                type="email"
                                name="register-email"
                                placeholder="E-mail"
                                value={registerEmail}
                                onChange={(event) => setRegisterEmail(event.target.value)}
                                className="no-mr"
                                disabled
                            />
                            <TextInput
                                mask={registerPhone.length > 18 ? "+55 (99) 99999-9999" : "+55 (99) 9999-99999"}
                                name="register-phone"
                                placeholder="Telefone"
                                value={registerPhone}
                                onChange={(event) => setRegisterPhone(event.target.value)}
                                className="no-mr"
                            />
                            <TextInput
                                mask="999.999.999-99"
                                name="register-cpf"
                                placeholder="CPF"
                                value={registerCpf}
                                onChange={(event) => setRegisterCpf(event.target.value)}
                                className="no-mr"
                                disabled
                            />
                            <TextInput
                                name="register-password"
                                placeholder="Senha"
                                value={registerPassword}
                                onChange={(event) => setRegisterPassword(event.target.value)}
                                className="no-mb"
                                type="password"
                            />
                            <PasswordStrengthBar
                                className="password-strength-bar"
                                password={registerPassword}
                                scoreWords={scoreWords}
                                shortScoreWord="Muito curta"
                            />
                            <TextInput
                                name="confirm-register-password"
                                placeholder="Confirmar Senha"
                                value={registerConfirmPassword}
                                onChange={(event) => setRegisterConfirmPassword(event.target.value)}
                                className="no-mr"
                                type="password"
                            />
                            <Button className="align-self-right" onClick={() => createProfile()} text="CRIAR PERFIL" width="160px"></Button>
                        </>
                }
            </div>
        </div>
    );
}

export default Login;